<template>
    <div>
        
        <b-modal id="create-or-update" :size="editing ? 'lg' : 'md'" :title="editing ? 'Güncelle' : 'Oluştur'" scrollable no-close-on-esc no-close-on-backdrop>
            <role-update v-if="editing" v-model="role"></role-update>
            <role-create v-else v-model="role"></role-create>
            <template #modal-footer><v-executer :loading="loading" :editing="editing" :action="createOrUpdate"></v-executer></template>
        </b-modal>

        <div class="row align-items-end mb-2">
            <div class="col-md-8 mb-1 mb-md-0">
                <h3>Rol</h3>
                <p class="mb-0">
                    Bir rol, önceden tanımlanmış menülere ve özelliklere erişim sağlar,<br/>
                    böylece atanan role bağlı olarak bir kullanıcı ihtiyaç duyduğu şeye erişebilir.
                </p>
            </div>
            <div class="col-md-4">
                <b-input-group class="input-group-merge">
                    <template #prepend>
                        <b-input-group-text >
                            <feather-icon icon="SearchIcon"></feather-icon>
                        </b-input-group-text>
                    </template>
                    <b-form-input v-model="search" placeholder="Ara..." ></b-form-input>
                </b-input-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <b-card no-body>
                    <div class="row">
                        <div class="col-sm-5">
                            <div class="d-flex align-items-end justify-content-center h-100">
                                <img :src="addRoleImage" class="img-fluid mt-2" alt="Image" width="91">
                            </div>
                        </div>
                        <div class="col-sm-7">
                            <div class="card-body text-sm-right text-center ps-sm-0">
                                <a href="javascript:void(0)" v-on:click="createNew" class="stretched-link text-nowrap">
                                    <span class="btn btn-primary mb-1 waves-effect waves-float waves-light">Yeni Rol Ekle</span>
                                </a>
                                <p class="mb-0 new-role-text">
                                    <span v-if="!c_roles.length && search.trim().length"><b>'{{search.slice(0,15)}}'</b> <br/> isimli rol bulunamadı.<br/></span>
                                    <span v-else>Yeni bir rol oluşturun.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
            <div v-for="(role,index) in c_roles" :key="index" class="col-md-4">
                <info-card :role="role" v-on:update="edit(index,$event)" :index="index" :additional="additional" v-on:delete="del($event)"></info-card>
            </div>
        </div>
    </div>
</template>

<script>
import { BCard,BInputGroup,BInputGroupText,BFormInput } from 'bootstrap-vue'
import InfoCard from './components/InfoCard.vue'
import { RoleApi } from '@/api/role'
import RoleUpdate from './components/RoleUpdate.vue'
import RoleCreate from './components/RoleCreate.vue'

export default {
    components:{
        BCard,
        BInputGroup,
        BInputGroupText,
        BFormInput,
        InfoCard,
        RoleUpdate,
        RoleCreate
    },
    mounted(){
        this.getRoles()
    },
    data(){
        return{
            roles:[],
            loading:false,
            search:"",
            editing:false,
            addRoleImage:require('@/assets/images/illustrations/faq.svg'),
            additional:{
                index:null,
                loading:false,
                editable:true
            },
            role:{
                Id:0,
                Name:"",
                RoleList:[],
                UserCount:0
            }
        }
    },
    computed:{
        c_roles(){
            return this.roles.filter(role=>{
                return role.Name.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    },
    methods:{
        clearForm(){
            this.editing = false
            this.role.Id = 0
            this.role.Name = ""
            this.role.RoleList = []
        },
        createNew(){
            this.clearForm()
            this.$showModal('create-or-update')
        },
        subRoleGroupSelect(){
            return this.role.RoleList.flatMap(r=> r.RoleList).map(rr=>{ return { Id:rr.Id,Authorized: rr.Authorized } })
        },
        createOrUpdate(){
            this.loading = true
            let roleList = this.subRoleGroupSelect()
            let role = {
                Id: this.role.Id,
                Name: this.role.Name,
                RoleList : this.editing ? roleList : []
            }
            RoleApi.CreateOrUpdate(this.editing, role)
                .then(res => {
                    if(res.data.Success) {
                        this.getRoles()
                        this.clearForm()
                        this.$hideModal('create-or-update')
                    }
                    this.$result(res)
                    this.loading = false
                }).
                catch(err => {
                    this.$result(null,err)
                    this.loading = false
                });
        },
        getRoles(){
            RoleApi.List().then(response=>{
                this.roles = response.data.Data
            })
        },
        edit(index,role){
            this.clearForm()
            this.editing = true
            this.additional.editable = false
            this.additional.loading = true
            this.additional.index = index
            RoleApi.Get(role.Id).then(response=>{
                this.role.Id = response.data.Data.RoleGroupId
                this.role.Name = response.data.Data.RoleGroupName
                this.role.RoleList = response.data.Data.RoleList
                this.role.UserCount = response.data.Data.UserCount
                this.$showModal('create-or-update')
                this.additional.editable = true
                this.additional.loading = false
                this.additional.index = null
            })
        },
        del(item){
            this.$confirm('Silmek istediğinize emin misiniz?',`Bu işlem ${item.Name} rolünü silecektir ve geri alınamaz.`).then(result=>{
                if(result){
                    RoleApi.Delete(item.Id).then(res=>{
                        if(res.data.Success) {
                            this.getRoles();
                        }
                        this.$result(res)
                    }).catch(err=>{
                        this.$result(null,err)
                    })
                }
            })
        }
    }
}
</script>

<style>
    .new-role-text{
        line-height: 1.2rem;
        font-size: 1rem;
    }
</style>