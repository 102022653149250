<template>
    <div class="row">
        <div class="col-12">
            <label class="form-label">İsim</label>
            <input type="text" class="form-control" v-model="value.Name">
        </div>
    </div>
</template>

<script>
export default {
    props:{
        value:Object
    }
}
</script>