<template>
    <div class="px-2">
        <div class="text-center mt-1">
            <h3>Rol Güncelleme</h3>
        </div>
        
        <b-input-group class="input-group-merge">
            <template #prepend>
                <b-input-group-text >
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </template>
            <b-form-input v-model="search" placeholder="Ara..." ></b-form-input>
        </b-input-group>

        <div class="row my-2">
            <div v-if="!search.length" class="col-12">
                <div class="border-bottom py-1">
                    <div class="row">
                        <div class="col-xl-3 role-group">
                            <span class="text-nowrap font-weight-bolder text-truncate mr-25">Administrator</span>
                            <span><feather-icon icon="InfoIcon"></feather-icon></span>
                        </div>
                        <div class="col-xl-9">
                            <div class="row g-2">
                                <div class="col-12">
                                    <b-form-checkbox id="selectAll" v-model="selectAll" v-on:change="selectOrUnselectAll($event)">Tümünü Seç</b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" v-for="(group,index) in c_groups" :key="index">
                <div class="border-bottom py-1">
                    <div class="row">
                        <div class="col-xl-3 role-group">
                            <b-form-checkbox :id="group.Identity" v-model="group.Authorized" v-on:change="selectAllGroupRoleList(group)"><b>{{group.GroupCode}}</b></b-form-checkbox>
                        </div>
                        <div class="col-xl-9">
                            <div class="row g-1">
                                <div class="col-12" v-for="(role,index) in group.RoleList" :key="index">
                                    <b-form-checkbox :disabled="disabledParentRelation(group,role)" :id="role.Identity" v-model="role.Authorized" v-on:change="controlSelectAll(group)">{{role.Name}}</b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="search && c_groups.length == 0" class="col-12">
                <div class="border-bottom text-center py-1">
                    Bu <b>'{{search}}'</b> izin bulunamadı.
                </div>
            </div>
        </div>
        <div class="mt-3">
            <label class="form-label">İsim</label>
            <input v-model="value.Name" class="form-control"/>
        </div>
    </div>
</template>

<script>
import { BInputGroup, BInputGroupText, BFormInput,BFormCheckbox } from 'bootstrap-vue'
import randomatic from 'randomatic'
export default {
    components:{
        BInputGroup,
        BInputGroupText,
        BFormInput,
        BFormCheckbox
    },
    props:{
        value:Object
    },
    data(){
        return{
            selectAll:false,
            search:''
        }
    },
    mounted(){
        this.configureGroup()
        this.controlAtStart()
    },
    methods:{
        configureGroup(){
            this.value.RoleList.forEach(g=> {
                g.Authorized = false
                g.Identity = randomatic('A0', 10)
                g.RoleList.forEach(r=> { r.Identity = randomatic('A0', 10) })
            })
        },
        controlAtStart(){
            this.value.RoleList.forEach(g => this.controlSelectAll(g))
        },
        selectOrUnselectAll(value){
            this.value.RoleList.forEach(g=> g.Authorized =value)
            this.value.RoleList.flatMap(p=>p.RoleList).forEach(p => { p.Authorized = value })          
        },
        controlSelectAll(group){
            const roleList = this.value.RoleList.flatMap(p=>p.RoleList)
            const selected = roleList.filter(p=>p.Authorized === true)
            if(roleList.length == selected.length){
                this.selectAll = true
            }else{
                this.selectAll = false
            }
            this.controlSelectAllGroupRoleList(group)
            this.controlParentRelation(group)
        },
        controlParentRelation(group){
            group.RoleList.forEach(role=>{
                let parent = group.RoleList.find(r=>r.RoleId == role.ParentRoleId)
                if(parent){
                    if(parent.Authorized == false){
                        this.$set(role,'Authorized',false)
                    }
                }
            })
        },
        disabledParentRelation(group,role){
            let parent = group.RoleList.find(r=>r.RoleId == role.ParentRoleId)
            if(parent){
                 if(parent.Authorized == true) return false
                else return true
            }else return false
        },
        selectAllGroupRoleList(group){
            group.RoleList.forEach(p=>p.Authorized = group.Authorized)
            this.controlSelectAll(group)
        },
        controlSelectAllGroupRoleList(group){
            const roleList = group.RoleList
            const selected = roleList.filter(p=>p.Authorized === true)
            if(roleList.length == selected.length){
                group.Authorized = true
            }else{
                group.Authorized = false
            }
            this.$forceUpdate()
        }
    },
    computed:{
        c_groups(){
            return this.value.RoleList
            .filter(p=> 
                p.RoleList.find(pp=>pp.Name.toLowerCase().includes(this.search)) ||
                p.GroupCode.toLowerCase().includes(this.search)
            )
        },
    },
}
</script>

<style>
    .role-group{
        background-color: var(--light);
        margin-bottom: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-radius: 0.375rem;
    }  
   
    @media (min-width: 1200px) {
        .role-group{
            background-color: transparent;
            padding-top: 0rem;
            padding-bottom: 0rem;
            border-radius: 0;
            border-right: 1px solid #ebebeb;
            margin-bottom: 0rem;
        }   
    }
</style>