<template>
    <b-card>
        <div class="d-flex justify-content-between">
        <span>Toplam {{role.UserCount}} kullanıcı</span>
        <ul class="list-unstyled d-flex align-items-center avatar-group mb-0">
            <li v-b-tooltip.hover title="Tooltip content" class="avatar avatar-sm pull-up" data-bs-original-title="Vinnie Mostowy">
                <!-- <img class="rounded-circle" :src="image('1-small.png')"  alt="Avatar"> -->
            </li>
        </ul>
        </div>
        <div class="d-flex justify-content-between align-items-end mt-1 pt-25">
            <div class="role-heading">
                <h4 class="font-weight-bolder">{{role.Name}}</h4>
                <a v-on:click="$emit('update',role)" class="role-edit-modal text-primary" :class="additional.editable ? null : 'disabled'">
                    <small class="font-weight-bolder">Düzenle</small>
                    <div v-if="additional.loading && index == additional.index" class="spinner-border spinner-border-sm ml-25"></div>
                </a>
            </div>
            <button v-on:click="$emit('delete',role)" class="btn btn-icon btn-sm waves-effect btn-flat-danger">
                <feather-icon icon="TrashIcon"></feather-icon>
            </button>
        </div>
    </b-card>
</template>

<script>
import { BCard,VBTooltip } from 'bootstrap-vue'

import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
export default {
    props:{
        role:Object,
        index:Number,
        additional:Object
    },
    directives:{
        'b-tooltip':VBTooltip
    },
    components:{
        BCard,
        FeatherIcon
    },
    methods:{
        image(image){
            return require(`@/assets/images/avatars/${image}`)
        }
    }
}
</script>

<style>

</style>